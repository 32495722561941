<template>
  <div>
    <el-dialog title="查看子订单" width="85%" :visible.sync="dialogTableVisible" @close="closeDialog">
      <el-table :data="packageData.data" :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }">
        <el-table-column label="序号" width="70">
          <template slot-scope="scope">
            <span>
              {{ scope.$index + (packageData.current_page - 1) * packageData.per_page + 1 }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="订单ID" prop="order_id" width="70"> </el-table-column>
        <el-table-column label="第三方订单号" prop="third_order_no" width="200"></el-table-column>
        <el-table-column label="姓名" prop="receive_name" width="100"></el-table-column>
        <el-table-column label="手机号" prop="receive_phone"></el-table-column>
        <el-table-column label="收货地址" width="320">
          <template slot-scope="scope">
            <span>
              {{ scope.row.receive_province + scope.row.receive_city + scope.row.receive_town + scope.row.receive_address }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="快递公司" prop="deliver_name"></el-table-column>
        <el-table-column label="物流编号" prop="waybill_code" width="230"></el-table-column>
        <el-table-column label="发货时间">
          <template slot-scope="scope">
            <span>{{ timeConversion(scope.row.createtime) }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column label="问题描述"></el-table-column>-->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="handle">
              <div v-if="scope.row.waybill_code === ''" @click="sendhuo(scope.row)">
                发货
              </div>
              <div v-if="scope.row.waybill_code === ''" @click="backmoney(scope.row)">
                退款
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background @current-change="currentChange" :page-size="packageData.per_page" layout="prev, pager, next" :current-page="packageData.current_page" :total="packageData.total"></el-pagination>
      </div>
      <span slot="footer">
        <el-button v-if="!showDownload" type="primary" size="small">下载</el-button>
        <a class="download" v-else :href="url + '/api/order/excel_order?id=' + id">
          <span>下载</span>
        </a>
        <el-button @click="dialogTableVisible = false" size="small">取消</el-button>
        <el-button @click="dialogTableVisible = false" type="primary" size="small ">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { reorder, rechildfund } from 'network/api'
import { timeConversion } from 'utils/utils'
export default {
  data() {
    return {
      dialogTableVisible: false,
      url: ''
    }
  },
  props: {
    showDialog: { default: false },
    packageData: Object,
    id: Number,
    showDownload: Boolean
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion() {
      return timeConversion
    }
  },
  created() {
    this.url = this.$url
  },
  methods: {
    // 发货
    sendhuo(item) {
      reorder({
        id: item.id,
        order_id: item.order_id,
        appkey: this.$appkey
      }).then(res => {
        if (res.code !== 0) {
          this.$$parent.showDialog = false
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      })
    },
    // 退款
    backmoney(item) {
      rechildfund({
        id: item.id,
        order_id: item.order_id,
        appkey: this.$appkey
      }).then(res => {
        if (res.code !== 0) {
          // this.$$parent.showDialog = false
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      })
    },
    // 监听关闭
    closeDialog() {
      this.$emit('closeDialog')
    },
    currentChange(val) {
      this.$emit('updatePackageData', val)
    }
  },
  watch: {
    showDialog(val) {
      this.dialogTableVisible = val
    }
  }
}
</script>
<style scoped src="styles/views/orderAdmin/orderTable.css"></style>
<style scoped src="styles/views/orderAdmin/childTable.css"></style>
