<template>
  <div class="content">
    <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
        <el-menu-item index="4">异常</el-menu-item>
        <el-menu-item index="5">取消</el-menu-item>
      </el-menu>
    </div>
    <div class="table">
      <el-table
        :data="orderData.data"
        style="width: 100%"
        :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}"
        :row-style="{height: '78px'}"
        >
        <el-table-column
          label="序号"
          width="70"
          >
          <template slot-scope="scope"><span>{{scope.$index+(orderData.current_page - 1) * orderData.per_page + 1}}</span></template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="200"
          >
          <template slot-scope="scope"><span>{{timeConversion(scope.row.createtime)}}</span></template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          label="T单号"
          width="200"
          >
        </el-table-column>
        <el-table-column
          label="商品信息"
          width="160"
          >
          <template slot-scope="scope">
            <div class="product-info">
              <img :src="scope.row.goods_images">
              <span>{{scope.row.goods_name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="子订单数"
          >
          <template slot-scope="scope">
            <div @click="eye(scope.row)" class="child-orders">
              {{scope.row.package_num}}笔<i class="el-icon-view"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_price"
          label="付款金额"
          >
        </el-table-column>
        <el-table-column
          label="订单状态"
          width="120"
          >
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.status === '1'" class="to-be-paid state">
              <span></span>待支付
            </div>
            <!-- 打单中 -->
            <div v-if="scope.row.status === '2'" class="in-order state">
              <span></span>打单中
            </div>
            <!-- 已出单 -->
            <div v-if="scope.row.status === '3'" class="order-issued state">
              <span></span>已出单
            </div>
            <!-- 异常 -->
            <div v-if="scope.row.status === '4'" class="abnormal state">
              <span></span>异常
            </div>
            <!-- 取消 -->
            <div v-if="scope.row.status === '5'" class="cancel state">
              <span></span>取消
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="平台"
          width="120"
          >
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.platform === 'is_tb'">淘宝/天猫</div>
              <div v-if="scope.row.platform === 'is_ali'">阿里巴巴</div>
              <div v-if="scope.row.platform === 'is_pinxixi'">拼多多</div>
              <div v-if="scope.row.platform === 'is_jd'">京东</div>
              <div v-if="scope.row.platform === 'is_dy'">抖音</div>
              <div v-if="scope.row.platform === 'is_others'">其他</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="异常">
          -
        </el-table-column>
        <el-table-column
          prop="order_tips"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
          width="200"
          >
          <template slot-scope="scope">
            <div class="handle">
              <div @click="payThis(scope.row)" v-if="scope.row.status === '1'">支付</div>
              <div @click="remove(scope.row.id)" v-if="scope.row.status === '1' || scope.row.status === '4'" class="remove">取消</div>
              <div v-if="scope.row.status !== '1'">
                <a :href="url+'/api/order/excel_order?id=' + scope.row.id">下载</a>
              </div>
              <div @click="eye(scope.row)">查看</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <child-table :showDialog="showDialog" @closeDialog="closeDialog" :packageData="packageData" @updatePackageData="updatePackageData" :id="id" :showDownload="showDownload"/>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="orderData.per_page"
          layout="prev, pager, next"
          :current-page="orderData.current_page"
          :total="orderData.total">
        </el-pagination>
      </div>
    </div>
    <!-- 付款Dialog -->
    <pay-dialog :fromList="fromList" :dialogShow="dialogShow" @closePayDialog="closePayDialog" :name="orderItem.goods_name" :price="orderItem.order_price" :money="money" :orderId="orderItem.id"/>
  </div>
</template>

<script>
import { getPackageList, getUserMoney } from 'network/api'
import { timeConversion } from 'utils/utils'
import ChildTable from './ChildTable'
import PayDialog from 'components/payDialog/PayDialog'

export default {
  components: { ChildTable, PayDialog },
  inject: ['mainReload'],
  data () {
    return {
      // activeIndex: '0',
      // orderData: {}, // 包含订单列表的对象
      showDialog: false,
      packageData: {}, // 包裹对象
      order_no: '',
      dialogShow: false,
      orderItem: {},
      money: '',
      id: null,
      showDownload: false,
      fromList: true,
      url: ''
    }
  },
  props: {
    orderData: Object, // 包含订单列表的对象
    activeIndex: String // 当前menu index
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    this.url = this.$url
  },
  methods: {
    // 根据分页改变获取数据
    currentChange (val) {
      this.$emit('curChange', val)
    },
    // menu激活回调
    selectChange (index) {
      this.$emit('menuChange', index)
    },
    // 查看子列表
    eye (item) {
      this.order_no = item.order_no
      this.id = item.id
      this.showDialog = true
      // 获取包裹列表
      getPackageList({ order_no: this.order_no, appkey: this.$appkey }).then(res => {
        this.packageData = res.data
        if (this.packageData.data.length !== 0) {
          this.showDownload = true
        } else {
          this.showDownload = false
        }
      })
    },
    closeDialog () {
      this.showDialog = false
    },
    // 子列表分页更新数据更新
    updatePackageData (val) {
      getPackageList({ order_no: this.order_no, page: val, appkey: this.$appkey }).then(res => {
        this.packageData = res.data
      })
    },
    // 取消
    remove (id) {
      this.$emit('remove', id)
    },
    download (data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '分析文件.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    // 支付
    payThis (data) {
      this.orderItem = data
      // 获取账户余额 并打开PayDialog
      getUserMoney({ appkey: this.$appkey }).then(res => {
        this.money = res.data.usermoney
        this.dialogShow = true
      })
    },
    // 关闭payDialog
    closePayDialog () {
      this.dialogShow = false
      this.mainReload() // 更新列表
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/orderTable.css">
</style>

<style>
.table .el-table tbody tr:hover > td {
  background-color: #e6f7ff;
}
</style>
